import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Calum | Developer',
  lang: 'en',
  description: 'Welcome to my dev site',
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Calum',
  subtitle: `I'm a Full Stack Developer`,
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'avatar-pic.png',
  paragraphOne: `I'm Calum, a Full Stack Developer with experience in e-commerce and healthtech. 
    My most recent role with Camaloon was as part of a growing dev team working on the public 
    website and back-office applications. Always keen to learn new technologies and apply 
    in different environments.`,
  paragraphTwo: `In the past I gained experience as a project manager working in industries
    such as rail and nuclear before making the move to my main interest of software and web
      development. I've carried over these skills of working collaboratively and driving
      deliverables and apply them daily in my work.`,
  resume: '', // if no resume, the button will not show up
};

// SKILLS DATA
export const skillsData = [
  {
    id: uuidv1(),
    img: 'react-redux.png',
    title: 'React (with Redux)',
  },
  {
    id: uuidv1(),
    img: 'ror.png',
    title: 'Ruby on Rails',
  },
  {
    id: uuidv1(),
    img: 'javascript.png',
    title: 'JavaScript',
  },
  {
    id: uuidv1(),
    img: 'html5.png',
    title: 'HTML5',
  },
  {
    id: uuidv1(),
    img: 'css.png',
    title: 'CSS3',
  },
  {
    id: uuidv1(),
    img: 'spring.png',
    title: 'Spring Boot',
  },
  {
    id: uuidv1(),
    img: 'kotlin.png',
    title: 'Kotlin',
  },
  {
    id: uuidv1(),
    img: 'python.png',
    title: 'Python',
  },
  {
    id: uuidv1(),
    img: 'postgresql.png',
    title: 'PostgreSQL',
  },
  {
    id: uuidv1(),
    img: 'aws.png',
    title: 'Cloud (AWS)',
  },
];

// EXPERIENCE DATA
export const experienceData = [
  {
    title: 'Full Stack Developer',
    company: 'Camaloon',
    location: 'Barcelona',
    range: 'June 2021 - Present',
    detailsOne:
      'Camaloon is an e-commerce business dedicated to the sale of personalised promotional products across all of Europe. Relocated to Barcelona to join a growing dev team working on the public website and back-office applications.',
    detailsTwo: `The primary technologies I've been using are React and Ruby on Rails and mainly for the conversion of legacy React/JS features into Rails.`,
    url: 'https://www.camaloon.com/',
  },
  {
    title: 'Full Stack Developer',
    company: 'EMIS',
    location: 'London',
    range: 'October 2019 - May 2021',
    detailsOne:
      'EMIS primarily focuses on building software web applications that allow patients to consent to medical data transfer. I joined a small dev team of 6 as a Full Stack Developer, working initially on a web application used by medical companies to obtain access to consented patient data for their products and services. Following this initial project, I have worked on a web interface to improve patient consent accessibility and connect it to healthcare vendors and a large database application to support effective surveillance of patient data for use in clinical trials and research projects.',
    detailsTwo: `The primary technologies I used were React with Redux, Spring Boot (Kotlin), PostgreSQL for the web applications and SQL with Presto for the database project. I have also used Amazon Web Services for deployment of these projects.`,
    url: 'https://www.dovetaillab.com/',
  },
  {
    title: 'Assistant Project Manager',
    company: 'Thales GTS',
    location: 'London',
    range: 'June 2017 - October 2019',
    detailsOne: `Supporting delivery of 4LM, the world's largest rail signalling upgrade to four of London's tube lines accounting for 40% of London Underground traffic. Worked within a project management team to deliver a section of the programme involving interfaces with several other railway lines and a large depot.`,
    detailsTwo: `Role involved interfacing with multiple client, internal, and subcontractor teams located in office and remotely, and driving delivery of key programme milestones within section. Led weekly meetings and daily standups with project teams and reporting progress to senior stakeholders. Developed skills in conveying technical information to management.`,
    url:
      'https://www.thalesgroup.com/en/countries/europe/united-kingdom/markets-we-operate/transportation/urban-mobility',
  },
  {
    title: 'Junior Project / Framework Manager',
    company: 'Amec Foster Wheeler',
    location: 'Gloucester / London',
    range: 'September 2014 - June 2017',
    detailsOne:
      'First graduate job out of university, primarily involved in supporting delivery of Hinkley Point C New Nuclear Programme.',
    detailsTwo:
      'Role involved managing and being representative for a multi-million pounds framework contract with a major nuclear new build client. Significant achievement in this role was maintaining an effective working relationship with client counterparts - both technical and commercial stakeholders - during a contractually challenging period, whilst also maintain on-going projects and coordinating bids for new packages of work.',
    url: 'https://www.woodplc.com/',
  },
  {
    title: 'BEng(Hons) Electrical & Mechanical Engineering',
    company: 'Strathclyde University',
    location: 'Glasgow',
    range: 'September 2010 - June 2014',
    detailsOne:
      'Introduced to programming and software development through modules such as: Engineering Computing and Analysis (using Python, C, MATLAB); Instrumentation and Microcontrollers (using C++), Mathematics.',
    detailsTwo:
      'Final year project involved extensive use of MATLAB to analyse large volume of historical weather data to determine pattern of wind turbine shutdowns during storm events.',
    url: 'https://www.strath.ac.uk/',
  },
];

// CONTACT DATA
export const contactData = {
  cta: `I'm currently looking for a new opportunity so please feel free to get in touch!`,
  btn: 'Get In Touch',
  email: 'hello@calmc.dev',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://linkedin.com/in/calummcmanus',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/cmcmanus8',
    },
  ],
};
